<template>
  <div class="gmv-create-users-modal">
    <b-modal
      class="gmv-create-users-modal-container"
      id="modal-3"
      scrollable
      title="Crear nuevo usuario"
      lazy
    >
      <div>
        <p class="gmv-create-users-modal-form__title">
          Todos los campos son requeridos
        </p>
        <div class="gmv-create-users-modal-form__inputs">
          <InputText
            type="text"
            placeholder="Nombre completo"
            v-model="users.name"
          />
        </div>
        <div class="gmv-create-users-modal-form__inputs">
          <InputText
            type="text"
            placeholder="Usuario"
            v-model="users.username"
          />
        </div>
        <div class="gmv-create-users-modal-form__inputs">
          <InputText
            type="password"
            placeholder="Contraseña"
            v-model="users.password"
          />
        </div>
        <div>
          <MultiSelect
            :options="banks"
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar banco"
            display="chip"
            v-model="users.bank_id"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-create-users-modal-button">
          <b-button
            class="gmv-create-users-modal-button__btn1"
            size="sm"
            @click="postUsers"
          >
            Agregar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
export default {
  inject: ["reload"],
  name: "CreateUsersModal",
  components: {
    InputText,
    MultiSelect,
  },
  data() {
    return {
      users: {
        name: null,
        username: null,
        password: null,
        bank_id: null,
      },
      route: null,
      usersArray: null,
      banks: [],
    };
  },
  async created() {
    this.banks = this.$store.getters["banks/showBanks"];
  },
  methods: {
    async postUsers() {
      let result = await this.$store.dispatch("users/postUsers", this.users);
      if (result.status == 201) {
        this.reload({ name: "UsersList" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 36rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}
::v-deep button.close {
  border: none;
  background: none;
}
::v-deep .modal {
  top: 60px;
  left: 15px;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 41%;
}
.gmv-create-users-modal-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__inputs {
    border-radius: 8px;
    margin: 10px 0px 15px 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 30rem;
  }
  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.p-multiselect {
  min-width: 30rem;
  margin-left: 60px;
  text-transform: capitalize;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  border-color: $color-primary-company;
  background: $color-primary-company;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: red;
  background: red;
}
::v-deep
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  color: #495057;
  background: #f5e5e5;
}
::v-deep .p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f5e5e5;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
::v-deep .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #f5e5e5;
  color: #495057;
  border-radius: 16px;
}
.gmv-create-users-modal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 40px;
    border: 1px solid $color-primary-company;
    width: 120px;
    border-radius: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
  }
}
::v-deep .modal-footer {
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
}
</style>