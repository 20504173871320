<template>
  <div class="gmv-edit-modal">
    <b-modal
      class="gmv-edit-modal-container"
      id="editUserModal"
      scrollable
      title="Editar usuario"
      lazy
    >
      <div>
        <p class="gmv-edit-modal-form__title">
          Todos los campos son requeridos
        </p>
        <div class="gmv-edit-modal-form__inputs">
          <InputText
            type="text"
            placeholder="Nombre completo"
            v-model="users.name"
          />
        </div>
        <div class="gmv-edit-modal-form__inputs">
          <InputText
            type="text"
            placeholder="Usuario"
            v-model="users.username"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="gmv-edit-modal-button">
          <b-button
            class="gmv-edit-modal-button__btn1"
            size="sm"
            @click="patchUsers(users)"
          >
            Editar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
export default {
  inject: ["reload"],
  name: "EditUsersModal",
  props: {
    selectedUser: Object,
  },
  components: {
    InputText,
    MultiSelect,
  },
  data() {
    return {
      users: {
        name: null,
        username: null,
        password: null,
      },
      usersArray: null,
    };
  },
  watch: {
    selectedUser() {
      this.users = { ...this.selectedUser };
    },
  },
  methods: {
    async patchUsers(usersData) {
      let payload = {};
      payload = usersData;
      payload.id = usersData.id;
      let result = await this.$store.dispatch(
        "users/patchUsers",
        payload,
        this.users
      );
      if (result.status == 204) {
        this.reload({ name: "UsersList" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 40rem;
}
::v-deep button.close {
  border: none;
  background: none;
}
::v-deep .modal {
  top: 60px;
  left: 35px;
}
.gmv-edit-modal-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &__inputs {
    border-radius: 8px;
    margin: 10px 0px 15px 60px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 30rem;
  }
  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }
}
::v-deep .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1 rem;
  display: flex;
  flex-direction: column;
}
.gmv-edit-modal-button {
  display: flex;
  justify-content: space-evenly;
  &__btn1 {
    background-color: $color-primary-company;
    height: 40px;
    border: 1px solid$color-primary-company;
    width: 120px;
    border-radius: 10px;
  }
  &__btn1:hover {
    background-color: red;
    color: black;
  }
}
::v-deep .modal-footer {
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
}
</style>