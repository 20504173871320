<template>
  <div class="gmv-admin">
    <div class="gmv-admin-container">
      <div>
        <EditUsersModal :selectedUser="selectedUser"></EditUsersModal>
      </div>
      <Loading v-if="isLoading" />
      <div v-if="!isLoading">
        <div class="gmv-admin-container__button">
          <div class="gmv-admin-container__button">
            <Button
              v-b-modal.modal-3
              label="Crear Usuario"
              icon="fas fa-user-plus"
              class="p-button-warning"
            /><CreateUsersModal></CreateUsersModal>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                type="text"
                v-model="searchUsers"
                placeholder="Buscar"
                @input="filterUsers"
              ></InputText>
            </span>
          </div>
          <div>
            <h6>Usuarios</h6>
            <b-avatar
              class="avatar-count"
              :v-model="getCount"
              :badge="countArray.count.toString()"
              variant="primary"
              badge-variant="danger"
            ></b-avatar>
          </div>
        </div>
        <div>
          <div class="gmv-admin-container__cell">
            <div v-for="(users, index) in filteredUsers" :key="index">
              <b-card-group class="gmv-admin-container__cards" deck>
                <b-card
                  sub-title="Asignado a: "
                  header-tag="header"
                  footer-tag="footer"
                  class="label"
                >
                  <template #header>
                    <h4 class="label">{{ users.name }}</h4>
                    <div>
                      <b-button-toolbar class="button-toolbar">
                        <b-dropdown class="button-dropdown" size="sm">
                          <b-dropdown-item
                            class="button-dropdown-item"
                            @click="deleteUsers(users)"
                            ><i class="fas fa-user-times"></i>
                            Eliminar</b-dropdown-item
                          >
                          <b-dropdown-item
                            v-b-modal.editUserModal
                            @click="openModal(users)"
                            ><i class="fas fa-user-edit"></i> Editar
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-toolbar>
                    </div>
                  </template>
                  <template>
                    <div class="body">
                      <h6 v-for="(bank, index) in users.banks" :key="index">
                        {{ bank.name }}
                      </h6>
                    </div>
                  </template>
                </b-card>
              </b-card-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import Button from "primevue/button";
import EditUsersModal from "@/components/EditUsersModal.vue";
import CreateUsersModal from "@/components/CreateUsersModal.vue";
import InputText from "primevue/inputtext";
import { BButtonToolbar } from "bootstrap-vue";
export default {
  inject: ["reload"],
  name: "admin",
  components: {
    Loading,
    Button,
    EditUsersModal,
    CreateUsersModal,
    InputText,
    BButtonToolbar,
  },
  data() {
    return {
      isLoading: false,
      users: {
        name: null,
        username: null,
        password: null,
        bank_id: null,
        banks: [
          {
            name: null,
          },
        ],
      },
      countArray: null,
      usersArray: null,
      selectedUser: null,
      changePasswordFlag: false,
      filteredUsers: null,
      searchUsers: "",
    };
  },
  async created() {
    this.getCount();
    this.isLoading = true;
    let response = await this.$store.dispatch("users/getUsers");
    this.isLoading = false;
    this.usersArray = response.data;
    this.filteredUsers = [...this.usersArray];
  },
  methods: {
    async getCount() {
      let response = await this.$store.dispatch("users/getCount");
      this.countArray = response.data;
    },
    async deleteUsers(userId) {
      this.swal({
        title: "¿Estas Seguro?",
        text: "Una vez eliminado, ¡no podrá recuperar este usuario!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let params = {};
          params.id = userId.id;
          let result = await this.$store.dispatch("users/deleteUsers", params);
          if (result.status == 204) {
            this.reload({ name: "UsersList" });
          }
        }
      });
    },
    openModal(usersInfo) {
      this.selectedUser = usersInfo;
    },
    filterUsers() {
      this.filteredUsers = this.usersArray.filter((item) => {
        return item.name.toLowerCase().includes(this.searchUsers.toLowerCase());
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gmv-admin-container {
  width: 100%;
  height: 100%;
  padding: 1rem 6rem;
  &__cell {
    text-align: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.body {
  overflow: auto;
  height: 30px;
}
.p-button-warning {
  color: white;
  background: $color-primary-company;
  border: 1px solid $color-primary-company;
  border-radius: 5px;
}
.avatar-count {
  background-color: #1d2124;
}
h6 {
  color: black;
  text-transform: capitalize;
}
::v-deep .p-input-icon-left,
.p-input-icon-right {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
}
.card {
  margin: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.425);
  border-radius: 1.25rem;
  width: 20rem;
}
.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-toolbar {
  border-radius: 5px;
}
::v-deep .dropdown-toggle {
  width: 22px;
  height: 25px;
  margin: 5px;
  background: #e9ecef;
  border: none;
}
::v-deep .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: red;
  border-color: red;
}
::v-deep .dropdown-toggle:hover {
  background: #dee2e6;
  border: none;
}
::v-deep .dropdown-menu.show {
  padding: 20px;
  margin-left: -80px;
  border-radius: 20px;
}
.label {
  color: black;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background-color: red;
  color: black;
  border-color: red;
}
.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
}
</style>